import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import "../../../App.css";
import { motion, useInView, useTransform } from "framer-motion";

import deskVideo from "../../../assets/desk_video.mp4";
import desk from "../../../assets/small_images/desk.png";
import gamingChair from "../../../assets/small_images/gamingChair.png";
import laptop from "../../../assets/small_images/laptop.png";
import pc from "../../../assets/small_images/pc.png";
import plant from "../../../assets/small_images/plant.png";
import roundPlant from "../../../assets/small_images/roundPlant.png";
import shelf from "../../../assets/small_images/shelf.png";
import waterBottle from "../../../assets/small_images/waterBottle.png";
import whiteChair from "../../../assets/small_images/whiteChair.png";
import yellowChair from "../../../assets/small_images/yellowChair.png";
import pc2 from "../../../assets/small_images/pc2.png";
import { isMobile } from "react-device-detect";

const CustomiseOffice = ({ scrollYProgress }) => {
  const officeRef = useRef(null);
  const inView = useInView(officeRef);

  const text1 = useRef(null);
  const text2 = useRef(null);

  const smallImages = [
    { name: "desk", img: desk },
    { name: "gamingChair", img: gamingChair },
    { name: "laptop", img: laptop },
    { name: "pc", img: pc },
    { name: "plant", img: plant },
    { name: "roundPlant", img: roundPlant },
    { name: "shelf", img: shelf },
    { name: "waterBottle", img: waterBottle },
    { name: "whiteChair", img: whiteChair },
    { name: "yellowChair", img: yellowChair },
    { name: "pc2", img: pc2 },
  ];

  const imagesRef = useRef(null);

  const [mouseX, setMouseX] = useState(700);
  const [mouseY, setMouseY] = useState(300);

  const handleMouseMove = (event) => {
    setMouseX(event.clientX);
    setMouseY(event.clientY);
  };

  const handleImagesPos = () => {
    const layer3Width = imagesRef?.current?.offsetWidth;
    const translateX = (mouseX / window.innerWidth) * 0.03 * layer3Width;
    const translateY = (mouseY / window.innerHeight) * 0.03 * layer3Width;
    return { x: translateX, y: translateY };
  };

  const scale = useTransform(scrollYProgress, [0.5, 1], [0.9, 1]);
  const rotate = useTransform(scrollYProgress, [0.5, 1], [isMobile ? 2 : 5, 0]);

  return (
    <motion.div
      className={styles.customiseOffice}
      ref={officeRef}
      id="office"
      style={{ scale, rotate }}
    >
      <div className={styles.customiseOfficeWindow}>
        <video
          src={deskVideo}
          autoPlay
          muted
          loop
          playsInline
          className={styles.deskVideo}
        />
        <div className={styles.officeTextSection}>
          <div className="heading" style={{ overflow: "hidden" }}>
            <motion.span
              className="clipText"
              ref={text1}
              initial={{ y: 140 }}
              animate={inView ? { y: 0 } : {}}
              transition={{
                duration: 1,
                ease: [0.25, 0.1, 0.25, 1],
                delay: 0.2,
              }}
            >
              CUSTOMISE
            </motion.span>
          </div>
          <div className="heading" style={{ overflow: "hidden" }}>
            <motion.span
              className="clipText"
              ref={text2}
              initial={{ y: 140 }}
              animate={inView ? { y: 0 } : {}}
              transition={{
                duration: 1,
                ease: [0.25, 0.1, 0.25, 1],
                delay: 0.4,
              }}
            >
              YOUR OFFICE
            </motion.span>
          </div>
          <div className="subHeadingWrapper">
            <div className="subHeading">
              Upgrade your office. Boost productivity with the best setup.
            </div>
          </div>
        </div>
        <div
          className={styles.smallImages}
          ref={imagesRef}
          onMouseMove={handleMouseMove}
          style={{
            marginLeft: handleImagesPos().x || "auto",
            marginTop: handleImagesPos().y || "auto",
          }}
        >
          {smallImages.map((object, index) => (
            <img
              src={object.img}
              className={styles?.[object?.name]}
              alt={object.name}
              key={index}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
};
export default CustomiseOffice;
