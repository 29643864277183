// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.App{
  overscroll-behavior: none;
  position: relative;
  background-color: white;
  max-width: 100vw;
  touch-action: pan-y;
}

.heading{
  font-size: 9rem;
  font-weight: 800;
  line-height: 0.8;
  z-index: 2;
  font-family: var(--font-barlow);
  white-space: nowrap;
}

.subHeadingWrapper{
  margin-top: 1.2rem;
  display: grid;
  font-family: var(--font-barlow);
  white-space: nowrap;
}

.subHeading{
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.2;
  z-index: 2;
}

.clipText {
  display: inline-block;
  clip-path: inset(100% 0 0 0);
  animation: revealText 0.8s ease-out forwards;
}

@keyframes revealText {
  to {
    clip-path: inset(0 0 0 0);
  }
}

.stickyContainer{
  height: 200svh;
  position: relative;
}

@media screen and (max-width: 768px) {
  .heading{
    font-size: 3.5rem;
    line-height: 0.9;
    position: relative;
    z-index: 3;
  }
  
  .subHeadingWrapper{
    margin-top: 0.8rem;
    position: relative;
    z-index: 3;
  }
  
  .sticky-trading-location-container{
    height: 200svh;
    position: relative;
    background-color: white;
    display: grid;
  }
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,kBAAkB;EAClB,uBAAuB;EACvB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,gBAAgB;EAChB,gBAAgB;EAChB,UAAU;EACV,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,aAAa;EACb,+BAA+B;EAC/B,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,eAAe;EACf,gBAAgB;EAChB,UAAU;AACZ;;AAEA;EACE,qBAAqB;EACrB,4BAA4B;EAC5B,4CAA4C;AAC9C;;AAEA;EACE;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE;IACE,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,kBAAkB;IAClB,kBAAkB;IAClB,UAAU;EACZ;;EAEA;IACE,cAAc;IACd,kBAAkB;IAClB,uBAAuB;IACvB,aAAa;EACf;AACF","sourcesContent":[".App{\n  overscroll-behavior: none;\n  position: relative;\n  background-color: white;\n  max-width: 100vw;\n  touch-action: pan-y;\n}\n\n.heading{\n  font-size: 9rem;\n  font-weight: 800;\n  line-height: 0.8;\n  z-index: 2;\n  font-family: var(--font-barlow);\n  white-space: nowrap;\n}\n\n.subHeadingWrapper{\n  margin-top: 1.2rem;\n  display: grid;\n  font-family: var(--font-barlow);\n  white-space: nowrap;\n}\n\n.subHeading{\n  font-weight: 400;\n  font-size: 1rem;\n  line-height: 1.2;\n  z-index: 2;\n}\n\n.clipText {\n  display: inline-block;\n  clip-path: inset(100% 0 0 0);\n  animation: revealText 0.8s ease-out forwards;\n}\n\n@keyframes revealText {\n  to {\n    clip-path: inset(0 0 0 0);\n  }\n}\n\n.stickyContainer{\n  height: 200svh;\n  position: relative;\n}\n\n@media screen and (max-width: 768px) {\n  .heading{\n    font-size: 3.5rem;\n    line-height: 0.9;\n    position: relative;\n    z-index: 3;\n  }\n  \n  .subHeadingWrapper{\n    margin-top: 0.8rem;\n    position: relative;\n    z-index: 3;\n  }\n  \n  .sticky-trading-location-container{\n    height: 200svh;\n    position: relative;\n    background-color: white;\n    display: grid;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
