import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import TypeWritterText from "../../molecules/TypeWritterText";
import TextRollup from "../../molecules/TextRollUp";
import MapWithBuildingsBackground from "../../molecules/MapWithBuildingsBakground";
import pipSvg from "../../../assets/PiP.svg";
import { isMobile } from "react-device-detect";

const LoadingScreen = ({ height, progressMain }) => {
  const [progress, setProgress] = useState(0);
  const [shouldRenderProgress, setShouldRenderProgress] = useState(false);
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShouldRenderProgress(true);
    }, 1900);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (shouldRenderProgress && progress < 100) {
        setProgress((prevProgress) => prevProgress + 1);
      }
    }, 10);
    return () => clearInterval(interval);
  }, [shouldRenderProgress, progress]);

  useEffect(() => {
    if (progressMain === 100) {
      const timer = setTimeout(() => {
        setShowLoadingScreen(false);
      }, 1800);
      return () => clearTimeout(timer);
    }
  }, [progressMain]);

  return (
    <div
      className={styles.loadingScreen}
      style={{
        height: `${height - 64}px`,
        top: showLoadingScreen ? 0 : "-110svh",
      }}
    >
      <MapWithBuildingsBackground progressMain={progressMain} />
      <div
        style={{ opacity: progressMain > 90 ? 0 : 1 }}
        className={styles.groupVertical}
      >
        <TypeWritterText text={"Initiate pip Trader"} delay={0} />
      </div>
      <div
        className={styles.groupVertical}
        style={{ opacity: progressMain > 90 ? 0 : 1 }}
      >
        <TypeWritterText
          text={"$ welcome_to_pip_trader --initiate"}
          delay={400}
        />
        <div className={styles.groupHorizontal}>
          <TypeWritterText text={"Initializing Pip Trader..."} delay={1200} />
          {shouldRenderProgress && (
            <div className={styles.progressBar}>
              [
              <div
                className={styles.progress}
                style={{
                  width: isMobile ? `${progress * 0.8}px` : `${progress * 2}px`,
                }}
              />{" "}
              ]<span>{progress}% </span>
            </div>
          )}
        </div>
      </div>
      <div
        className={styles.groupVertical}
        style={{ opacity: progressMain > 90 ? 0 : 1 }}
      >
        <TypeWritterText
          text={`$ create_character --name "Captain Pip" --role "Trader"`}
          delay={3300}
        />
        <TypeWritterText
          text={"Creating your legendary trading character... Done!"}
          delay={4300}
        />
        <TypeWritterText
          text={`"Congratulations! You are now "Captain Pip", a fearless trader ready to conquer the markets!"`}
          delay={5400}
        />
      </div>

      <div className={styles.pipLogoWithLoader}>
        <div className={styles.pipEcoSystemText}>
          <img src={pipSvg} alt="PiP" width={isMobile ? 70 : 140} />
          <TextRollup />
        </div>
        <span
          className={styles.loadingText}
          style={{ opacity: progressMain !== 100 ? 1 : 0 }}
        >
          LOADING...
        </span>
        <div
          className={styles.progressBar}
          style={{ opacity: progressMain !== 100 ? 1 : 0 }}
        >
          [
          <div
            className={styles.progress}
            style={{
              width: isMobile
                ? `${progressMain * 1}px`
                : `${progressMain * 2}px`,
            }}
          />
          ]<span>{progressMain}% </span>
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
