import React, { useRef } from "react";
import { motion } from "framer-motion";
import styles from "./styles.module.css";
import "../../../App.css";
import aiPowered from "../../../assets/ai_powered/aiPoweredGaming.png";
import robot from "../../../assets/ai_powered/big_robot.png";
import chipset from "../../../assets/ai_powered/chipset.png";
import greenServer from "../../../assets/ai_powered/green_server.png";
import pinkServer from "../../../assets/ai_powered/pink_server.png";
import blueServer from "../../../assets/ai_powered/blue_server.png";
import blueLaptop from "../../../assets/ai_powered/laptop_blue.png";
import whiteLaptop from "../../../assets/ai_powered/laptop_white.png";

import { useInView } from "framer-motion";
const AIPoweredGaming = () => {
  const aiPoweredRef = useRef(null);
  const inView = useInView(aiPoweredRef);
  const text1 = useRef(null);
  const text2 = useRef(null);
  return (
    <div className={styles.aiPoweredGaming} ref={aiPoweredRef} id="gaming">
      <div className={styles.aiPowered}>
        <img src={aiPowered} alt="aiPowered" />
      </div>
      <div className={styles.textSection}>
        <div className="heading" style={{ overflow: "hidden" }}>
          <motion.span
            className="clipText"
            ref={text1}
            initial={{ y: 140 }}
            animate={inView ? { y: 0 } : {}}
            transition={{
              duration: 1,
              ease: [0.25, 0.1, 0.25, 1],
              delay: 0.2,
            }}
          >
            AI-POWERED
          </motion.span>
        </div>

        <div className="heading" style={{ overflow: "hidden" }}>
          <motion.span
            className="clipText"
            ref={text2}
            initial={{ y: 140 }}
            animate={inView ? { y: 0 } : {}}
            transition={{
              duration: 1,
              ease: [0.25, 0.1, 0.25, 1],
              delay: 0.4,
            }}
          >
            GAMING
          </motion.span>
        </div>
        <div className="subHeadingWrapper">
          <div className="subHeading">AI-driven features for real-time</div>
          <div className="subHeading"> insights and randomly generated</div>
          <div className="subHeading"> world wide events.</div>
        </div>
      </div>
      <img src={robot} alt="ai powered bg" className={styles.robot} />

      <img src={chipset} alt="chipset" className={styles.chipImage1} />
      <img src={chipset} alt="chipset" className={styles.chipImage2} />
      <img src={chipset} alt="chipset" className={styles.chipImage3} />
      <img src={chipset} alt="chipset" className={styles.chipImage4} />
      <img src={chipset} alt="chipset" className={styles.chipImage5} />
      <img src={chipset} alt="chipset" className={styles.chipImage6} />

      <img src={greenServer} alt="server" className={styles.greenServer} />
      <img src={blueServer} alt="server" className={styles.blueServer} />
      <img src={pinkServer} alt="server" className={styles.pinkServer} />

      <img src={whiteLaptop} alt="server" className={styles.whiteLaptop} />
      <img src={blueLaptop} alt="server" className={styles.blueLaptop} />
    </div>
  );
};

export default AIPoweredGaming;
