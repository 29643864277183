import React, { useState, useEffect } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import { useInView } from "react-intersection-observer";
import styles from "./styles.module.css";
import "../../../App.css";
import TradeWar1 from "../../../assets/tradeWarChar1.png";
import TradeWar2 from "../../../assets/tradeWarChar2.png";
import chart from "../../../assets/chart.png";
import { isMobile } from "react-device-detect";

const TradeWars = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });
  const [animationState, setAnimationState] = useState(false);

  const setAnimationFalse = () => {
    setTimeout(() => {
      setAnimationState(false);
    }, 400);
  };

  useEffect(() => {
    if (inView) {
      setAnimationState(true);
    } else if (!inView && animationState) {
      setAnimationFalse();
    }
  }, [inView, animationState]);

  function useParallax(value, distance) {
    return useTransform(value, [0, 1], [-distance, distance]);
  }

  const { scrollYProgress } = useScroll({ target: ref });
  const y = useParallax(scrollYProgress, 300);

  const text1 = React.useRef(null);
  const text2 = React.useRef(null);
  const text3 = React.useRef(null);

  return (
    <div className={styles.tradeWars} ref={ref} id="wars">
      <div className={styles.tradeWarsWindow}>
        <div className={styles.diagonal} />
        <div className={styles.left}>
          {isMobile ? (
            <motion.img
              src={TradeWar1}
              className={styles.tradeWar1}
              style={{
                y,
                position: "absolute",
                left: "-0.59rem",
              }}
            />
          ) : (
            <motion.img
              src={TradeWar1}
              className={styles.tradeWar1}
              style={{
                y,
                position: "absolute",
                display: !inView ? "none" : "unset",
              }}
              initial={{ left: "-40rem" }}
              animate={animationState ? { left: "-1rem" } : { left: "-40rem" }}
              transition={{ duration: 1, ease: [0.645, 0.045, 0.355, 1] }}
            />
          )}
        </div>
        <div className={styles.chart}>
          <img src={chart} alt="chart" />
        </div>
        <div className={styles.right}>
          {isMobile ? (
            <motion.img
              src={TradeWar2}
              className={styles.tradeWar2}
              style={{
                y,
                position: "absolute",
                right: "-0.59rem",
              }}
            />
          ) : (
            <motion.img
              src={TradeWar2}
              className={styles.tradeWar2}
              style={{
                y,
                position: "absolute",
                display: !inView ? "none" : "unset",
              }}
              initial={{ right: "-40rem" }}
              animate={
                animationState ? { right: "-1rem" } : { right: "-40rem" }
              }
              transition={{ duration: 1, ease: [0.645, 0.045, 0.355, 1] }}
            />
          )}
        </div>
        <div className={styles.textSection}>
          <div className="heading" style={{ overflow: "hidden" }}>
            <motion.span
              className="clipText"
              ref={text1}
              initial={{ y: 140 }}
              animate={inView ? { y: 0 } : {}}
              transition={{
                duration: 1,
                ease: [0.25, 0.1, 0.25, 1],
                delay: 0,
              }}
            >
              JOIN
            </motion.span>
          </div>
          <div className="heading" style={{ overflow: "hidden" }}>
            <motion.span
              className="clipText"
              ref={text2}
              initial={{ y: 140 }}
              animate={inView ? { y: 0 } : {}}
              transition={{
                duration: 1,
                ease: [0.25, 0.1, 0.25, 1],
                delay: 0.2,
              }}
            >
              TRADE
            </motion.span>
          </div>
          <div className="heading" style={{ overflow: "hidden" }}>
            <motion.span
              className="clipText"
              ref={text3}
              initial={{ y: 140 }}
              animate={inView ? { y: 0 } : {}}
              transition={{
                duration: 1,
                ease: [0.25, 0.1, 0.25, 1],
                delay: 0.4,
              }}
            >
              WARS
            </motion.span>
          </div>
          <div className="subHeadingWrapper">
            <div className="subHeading">
              Challenge other traders. Compete, climb the
            </div>
            <div className="subHeading">leaderboard and earn rewards.</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradeWars;
