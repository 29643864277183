import React, { useState } from "react";
import styles from "./styles.module.css";
import { validateFormData } from "../../../utils";
import { countries } from "../../../constants";

const RegistrationModal = ({ setShowRegModal }) => {
  const [errors, setErrors] = useState([]);
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [wallet, setWallet] = useState("");
  const [source] = useState("");
  const [loading, setLoading] = useState(false);
  const [registrationgComplete, setRegistrationComplete] = useState(false);
  const [genericError, setGenericError] = useState("");

  const url =
    "https://prod-26.uksouth.logic.azure.com/workflows/c8a43e443aaf44aab5e5e985a9b72386/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=i1DfYTxeyaXJExqu-YmSWSRtBUv2wQSwgykLsWGrO1Y";

  const handleSubmit = () => {
    if (registrationgComplete) {
      setShowRegModal(false);
      return;
    }
    if (validateFormData({ email, country, wallet }).isValid) {
      setLoading(true);
      const options = {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        body: JSON.stringify({
          Email: email,
          Country: country,
          First_Name: firstName,
          Last_Name: "",
          Wallet_Address: wallet,
          Method: source,
        }),
      };
      try {
        fetch(url, options).then((response) => {
          if (response.ok) {
            setRegistrationComplete(true);
            setLoading(false);
          }
        });
      } catch (error) {
        setLoading(false);
        setGenericError("Something went wrong, try again later");
      }
    } else {
      setErrors(
        validateFormData({
          email,
          country,
          wallet,
        }).errors
      );
    }
  };

  return (
    <div>
      <form
        className={styles.registrationForm}
        onSubmit={(e) => e.preventDefault()}
      >
        <label htmlFor="email">{`Email: * ${errors.Email ?? ""}`}</label>
        <input
          type="email"
          name="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <label htmlFor="country">{`Country: * ${errors.Country ?? ""}`}</label>
        <select name="country" onChange={(e) => setCountry(e.target.value)}>
          <option value="">Select a country...</option>
          {countries.map((country, index) => (
            <option key={index} value={country}>
              {country}
            </option>
          ))}
        </select>
        <label htmlFor="wallet">{`Wallet Address: ${
          errors.wallet ?? ""
        }`}</label>
        <input
          type="text"
          name="wallet"
          value={wallet}
          onChange={(e) => setWallet(e.target.value)}
        />
        <label htmlFor="firstName">Name:</label>
        <input
          type="text"
          name="firstname"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        {genericError ? <span>{genericError}</span> : null}
        <button
          type="submit"
          onClick={handleSubmit}
          onTouchStart={handleSubmit}
          style={{
            backgroundColor: registrationgComplete ? "#670fdc" : null,
            color: registrationgComplete ? "white" : null,
          }}
        >
          {" "}
          {loading
            ? "Loading..."
            : registrationgComplete
            ? "Success! You can now close the window!"
            : "Submit"}
        </button>
        <button
          className={styles.closeBtn}
          onClick={() => setShowRegModal(false)}
        >
          Close
        </button>
      </form>
    </div>
  );
};

export default RegistrationModal;
