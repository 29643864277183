// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_typeWritterText__jUuoO{
    text-transform: uppercase;
    line-height: 1.3;
    font-size: 0.8rem;
    position: relative;
    z-index: 2;
}


@media screen and (max-width: 768px) {
    .styles_typeWritterText__jUuoO{
        font-size: 0.6rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/TypeWritterText/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;AACd;;;AAGA;IACI;QACI,iBAAiB;IACrB;AACJ","sourcesContent":[".typeWritterText{\n    text-transform: uppercase;\n    line-height: 1.3;\n    font-size: 0.8rem;\n    position: relative;\n    z-index: 2;\n}\n\n\n@media screen and (max-width: 768px) {\n    .typeWritterText{\n        font-size: 0.6rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"typeWritterText": `styles_typeWritterText__jUuoO`
};
export default ___CSS_LOADER_EXPORT___;
