import React, { useRef, useState } from "react";
import styles from "./styles.module.css";
import { motion, useInView } from "framer-motion";
import chartLeft from "../../../assets/chartLeft.png";
import chartRight from "../../../assets/chartRight.png";
import playStore from "../../../assets/playstore.png";
import appStore from "../../../assets/appstore.png";
import overlay from "../../../assets/overlay.png";
import overlayMobile from "../../../assets/overlayMobile.png";
import pcWithCoin from "../../../assets/pcWithCoin.png";
import { isMobile } from "react-device-detect";

const EarnsYouPip = () => {
  const earnPipRef = useRef(null);

  const text1 = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);

  const inView = useInView(earnPipRef);

  const chartLeftRef = useRef(null);
  const chartRightRef = useRef(null);

  const [mouseX, setMouseX] = useState(0);

  const chartLeftMargin = () => {
    const chartWidth = chartLeftRef?.current?.offsetWidth;
    const translateX = (mouseX / window.innerWidth) * 0.04 * chartWidth;
    return translateX;
  };

  const chartRightMargin = () => {
    const chartWidth = chartRightRef?.current?.offsetWidth;
    const translateX = (mouseX / window.innerWidth) * 0.04 * chartWidth;
    return translateX;
  };

  const handleMouseMove = (event) => {
    setMouseX(event.clientX);
  };

  return (
    <div
      className={styles.EarnsYouPip}
      onMouseMove={handleMouseMove}
      id="earnPip"
    >
      <div className={styles.textSection}>
        <div
          className="heading"
          style={{ overflow: "hidden" }}
          ref={earnPipRef}
        >
          <motion.span
            className="clipText"
            ref={text1}
            initial={{ y: 140 }}
            animate={inView ? { y: 0 } : {}}
            transition={{
              duration: 1,
              ease: [0.25, 0.1, 0.25, 1],
              delay: 0,
            }}
          >
            THE TRADING
          </motion.span>
        </div>
        <div className="heading" style={{ overflow: "hidden" }}>
          <motion.span
            className="clipText"
            ref={text2}
            initial={{ y: 140 }}
            animate={inView ? { y: 0 } : {}}
            transition={{
              duration: 1,
              ease: [0.25, 0.1, 0.25, 1],
              delay: 0.2,
            }}
          >
            GAME THAT
          </motion.span>
        </div>
        <div className="heading" style={{ overflow: "hidden" }}>
          <motion.span
            className="clipText"
            ref={text3}
            initial={{ y: 140 }}
            animate={inView ? { y: 0 } : {}}
            transition={{
              duration: 1,
              ease: [0.25, 0.1, 0.25, 1],
              delay: 0.4,
            }}
          >
            EARNS YOU $PiPW
          </motion.span>
        </div>
      </div>
      <div className="subHeadingWrapper">
        <span className="subHeading">
          Earn real crypto while you play. Build your team, pick locations, and
        </span>
        <span className="subHeading">customise your office to succeed.</span>
      </div>
      <div className={styles.images}>
        <img
          src={chartLeft}
          alt="iphone"
          width={isMobile ? "40%" : "100%"}
          className={styles.chartImg1}
          ref={chartLeftRef}
          style={{
            marginLeft: isMobile ? "unset" : chartLeftMargin() || "auto",
          }}
        />
        <img src={pcWithCoin} alt="pc" width="80%" className={styles.pcImg} />
        <img
          src={chartRight}
          alt="iphone"
          width={isMobile ? "40%" : "100%"}
          className={styles.chartImg2}
          ref={chartRightRef}
          style={{
            marginLeft: isMobile ? "unset" : chartRightMargin() || "auto",
          }}
        />
        <div className={styles.downloadStoreBtn}>
          <img src={playStore} alt="android" width="30%" />
          <img src={appStore} alt="iphone" width="30%" />
        </div>
        {!isMobile ? (
          <img src={overlay} className={styles.overlay} alt="overlay" />
        ) : null}
      </div>
      {isMobile ? (
        <img
          src={overlayMobile}
          className={styles.overlayMobile}
          alt="overlay"
        />
      ) : null}
    </div>
  );
};

export default EarnsYouPip;
