// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@600&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root{
  --font-courier:'Courier New', Courier, monospace;
  --font-space-grotesk:'Space Grotesk', sans-serif;
  --font-barlow: 'Barlow Condensed', sans-serif;
  --font-white: #FFFFFF;
  --bg-purple: #690DDE;
  background-color: black;
}

*{
  -ms-overflow-style: none;
  scrollbar-width: none;
  touch-action: pan-y;
}

*::-webkit-scrollbar {
  display: none;
}

body {
  padding: 0;
  margin: 0;
}


`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAGA;EACE,gDAAgD;EAChD,gDAAgD;EAChD,6CAA6C;EAC7C,qBAAqB;EACrB,oBAAoB;EACpB,uBAAuB;AACzB;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;EACrB,mBAAmB;AACrB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,UAAU;EACV,SAAS;AACX","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@600&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');\n\n:root{\n  --font-courier:'Courier New', Courier, monospace;\n  --font-space-grotesk:'Space Grotesk', sans-serif;\n  --font-barlow: 'Barlow Condensed', sans-serif;\n  --font-white: #FFFFFF;\n  --bg-purple: #690DDE;\n  background-color: black;\n}\n\n*{\n  -ms-overflow-style: none;\n  scrollbar-width: none;\n  touch-action: pan-y;\n}\n\n*::-webkit-scrollbar {\n  display: none;\n}\n\nbody {\n  padding: 0;\n  margin: 0;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
