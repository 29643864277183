import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { isMobile } from "react-device-detect";

const words = ["world", "trader", "academy"];

const TextRollup = () => {
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % words.length);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ overflow: "hidden", height: isMobile ? "unset" : "3.5rem" }}>
      <AnimatePresence>
        {words.map(
          (word, i) =>
            i === index && (
              <motion.div
                key={word}
                initial={{ y: "100%", opacity: 0 }}
                animate={{ y: "0%", opacity: 1 }}
                exit={{ y: "-100%", opacity: 0 }}
                transition={{ duration: 0.5 }}
                style={{
                  position: "absolute",
                  fontSize: isMobile ? "2rem" : "4rem",
                  fontWeight: "bold",
                  marginLeft: isMobile ? "0.5rem" : "1rem",
                  marginTop: isMobile ? "-1.3rem" : "-0.9rem",
                  fontFamily: `"Space Grotesk", sans-serif`,
                }}
              >
                {word}
              </motion.div>
            )
        )}
      </AnimatePresence>
    </div>
  );
};

export default TextRollup;
