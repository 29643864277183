import React, { useEffect } from "react";
import styles from "./styles.module.css";
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import globeImg from "../../../assets/globeImg.png";
import globeFullImg from "../../../assets/globeFull.png";
import callOfDuty from "../../../assets/companies/call_of_duty.svg";
import halo from "../../../assets/companies/halo.svg";
import magic from "../../../assets/companies/magic.svg";
import mkraken from "../../../assets/companies/kraken.svg";
import king from "../../../assets/companies/king.svg";
import logo from "../../../assets/logo.svg";
import joinDiscord from "../../../assets/joinDiscord.png";
import purpleDiscord from "../../../assets/purpleDiscord.png";
import purpleX from "../../../assets/purpleX.png";
import storeBtns from "../../../assets/storeBtns.png";

import osman from "../../../assets/team/osman.png";
import thaj from "../../../assets/team/thaj.png";
import anil from "../../../assets/team/anil.png";
import ben from "../../../assets/team/ben.png";
import saad from "../../../assets/team/saad.png";
import jalil from "../../../assets/team/jalil.png";
import rayan from "../../../assets/team/rayan.png";
import adam from "../../../assets/team/adam.png";
import abdi from "../../../assets/team/abdi.png";
import chris from "../../../assets/team/chris.png";
import orlagh from "../../../assets/team/orlagh.png";
import fadi from "../../../assets/team/fadi.png";
import collin from "../../../assets/team/collin.png";
import { isMobile } from "react-device-detect";

const Experts = () => {
  const team = [
    { name: "osman", img: osman },
    { name: "thaj", img: thaj },
    { name: "anil", img: anil },
    { name: "ben", img: ben },
    { name: "saad", img: saad },
    { name: "jalil", img: jalil },
    { name: "rayan", img: rayan },
    { name: "adam", img: adam },
    { name: "abdi", img: abdi },
    { name: "chris", img: chris },
    { name: "orlagh", img: orlagh },
    { name: "fadi", img: fadi },
    { name: "collin", img: collin },
  ];

  const companies = [
    { name: "Call of Duty", img: callOfDuty },
    { name: "HALO", img: halo },
    { name: "Magic", img: magic },
    { name: "king", img: king },
    { name: "mKraken", img: mkraken },
  ];

  const controls = useAnimation();
  const [ref, inView] = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView) {
      controls.start((i) => ({
        opacity: 1,
        y: 0,
        transition: { delay: i * 0.09, type: "spring", stiffness: 200 },
      }));
    } else {
      controls.start((i) => ({
        opacity: i === team.length ? 1 : 0,
        y: i === team.length ? 10 : -100,
        transition: { delay: 0.1 },
      }));
    }
  }, [controls, inView, team.length]);

  return (
    <div className={styles.experts} id="experts">
      <div className="heading">BUILT BY</div>
      <div className="heading">EXPERTS</div>
      <div className="subHeadingWrapper">
        <div className="subHeading">
          Crafted by pros in gaming, ai, and tech.
        </div>
        <div className="subHeading">They've worked with the best.</div>
      </div>
      <div className={styles.logos}>
        {Array(4)
          .fill("0")
          .map((_) => (
            <div className={styles.logo_items}>
              {companies.map((company) => (
                <img
                  className={styles.logo}
                  style={
                    company.name === "king"
                      ? {
                          transform: "scale(0.6)",
                        }
                      : {}
                  }
                  key={company.name}
                  src={company.img}
                  alt={company.name}
                />
              ))}
            </div>
          ))}
      </div>
      <div ref={ref} className={styles.globe}>
        <motion.img
          src={isMobile ? globeFullImg : globeImg}
          alt="globe"
          className={styles.globeImg}
          custom={team.length}
          initial={{ opacity: 1, y: 10 }}
          animate={controls}
        />
        {team.map((member, index) => (
          <motion.img
            key={member}
            custom={index}
            initial={{ opacity: 0, y: -270 }}
            animate={controls}
            src={member.img}
            alt={member.name}
            className={styles?.[member.name]}
          />
        ))}
        <img src={logo} alt="logo" className={styles.pipLogo} />
        <img
          src={joinDiscord}
          alt="joinDiscord"
          className={styles.joinDiscord}
          onClick={() => window.open("https://discord.com/invite/pipworld")}
        />
        <div className={styles.socials}>
          <img
            src={purpleDiscord}
            alt="purpleDiscord"
            onClick={() => window.open("https://discord.com/invite/pipworld")}
          />
          <img
            src={purpleX}
            alt="purpleX"
            onClick={() => window.open("https://x.com/ThePiPWorld")}
          />
        </div>
        <div className={styles.comingSoon}>
          <span>Coming soon on</span>
          <img src={storeBtns} alt="storeBtns" />
        </div>
      </div>
    </div>
  );
};

export default Experts;
