import React from "react";
import styles from "./styles.module.css";
import { isMobile } from "react-device-detect";
import map from "../../../assets/map_background.png";
import bigBuilding from "../../../assets/buildings/building6.png";
import mediumBuilding from "../../../assets/buildings/building4.png";
import smallBuilding from "../../../assets/buildings/building7.png";
import overlay from "../../../assets/overlay.png";
import overlayMobile from "../../../assets/overlayMobile.png";

const MapWithBuildingsBackground = ({ progressMain }) => {
  return (
    <div>
      <img
        src={map}
        alt="background"
        className={styles.background}
        width="100%"
        height="100%"
        style={{ opacity: progressMain > 69 ? 1 : 0 }}
      />
      <img
        src={bigBuilding}
        alt="buildings"
        className={styles.bigBuilding}
        width={isMobile ? "40%" : "25%"}
        style={{
          bottom:
            progressMain > 69
              ? isMobile
                ? "-10svh"
                : "-65svh"
              : isMobile
              ? "-100svh"
              : "-200svh",
          opacity: progressMain > 40 ? 1 : 0,
        }}
      />
      <img
        src={mediumBuilding}
        alt="buildings"
        className={styles.mediumBuilding}
        width={isMobile ? "26%" : "9%"}
        style={{
          bottom: progressMain > 69 ? (isMobile ? "-3svh" : "-7svh") : "-50svh",
          opacity: progressMain > 40 ? 1 : 0,
        }}
      />
      <img
        src={smallBuilding}
        alt="buildings"
        className={styles.smallBuilding}
        width={isMobile ? "20%" : "7%"}
        style={{
          bottom: progressMain > 69 ? (isMobile ? "-3svh" : "-7svh") : "-50svh",
          opacity: progressMain > 40 ? 1 : 0,
        }}
      />
      <img
        src={isMobile ? overlayMobile : overlay}
        alt="overlay"
        className={styles.overlay}
        width="100%"
      />
    </div>
  );
};

export default MapWithBuildingsBackground;
