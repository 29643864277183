import React, { useState, useRef, useEffect } from "react";
import styles from "./styles.module.css";
import { motion, useInView } from "framer-motion";
import logoWhite from "../../../assets/logo.svg";
import discord from "../../../assets/discord.svg";
import x from "../../../assets/x.svg";
import pipOfficeBg from "../../../assets/pipOfficeBg.png";
import heroSectionLayer2Bg from "../../../assets/heroSectionLayer2Bg.png";
import heroSectionLayer3Bg from "../../../assets/heroSectionLayer3Bg.png";
import overlay from "../../../assets/overlay.png";
import arrowDown from "../../../assets/arrowDown.png";
import { isMobile } from "react-device-detect";

const HeroSection = ({ progressMain, setShowRegModal }) => {
  const layer2Ref = useRef(null);
  const layer3Ref = useRef(null);
  const headingRef = useRef(null);

  const inView = useInView(headingRef);
  const text1 = useRef(null);

  const [mouseX, setMouseX] = useState(700);
  const [mouseY, setMouseY] = useState(300);

  const handleMouseMove = (event) => {
    setMouseX(event.clientX);
    setMouseY(event.clientY);
  };

  const handleLayer1Translate = () => {
    const layer2Width = layer2Ref?.current?.offsetWidth;
    const translateX = (mouseX / window.innerWidth) * 0.004 * layer2Width;
    const translateY = (mouseY / window.innerHeight) * 0.004 * layer2Width;
    return { x: translateX, y: translateY };
  };

  const handleLayer2TranslateX = () => {
    const layer2Width = layer2Ref?.current?.offsetWidth;
    const translateX = (mouseX / window.innerWidth) * 0.007 * layer2Width;
    const translateY = (mouseY / window.innerHeight) * 0.007 * layer2Width;
    return `translateX(${translateX}px) translateY(${translateY}px)`;
  };

  const handleLayer3TranslateX = () => {
    const layer3Width = layer3Ref?.current?.offsetWidth;
    const translateX = (mouseX / window.innerWidth) * 0.02 * layer3Width;
    const translateY = (mouseY / window.innerHeight) * 0.02 * layer3Width;
    return `translateX(${translateX}px) translateY(${translateY}px)`;
  };

  const headingTransforn = () => {
    const headingWidth = headingRef?.current?.offsetWidth;
    const translateX = (mouseX / window.innerWidth) * 0.07 * headingWidth;
    const translateY = (mouseY / window.innerHeight) * 0.07 * headingWidth;
    return { x: translateX - 8, y: translateY };
  };

  const useScrolling = () => {
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
      let scrollTimeout;

      const handleScroll = () => {
        setIsScrolling(true);

        clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(() => {
          setIsScrolling(false);
        }, 150); // Adjust the delay as needed
      };

      window.addEventListener("scroll", handleScroll);

      return () => {
        clearTimeout(scrollTimeout);
        window.removeEventListener("scroll", handleScroll);
      };
    }, []);

    return isScrolling;
  };

  const isScrolling = useScrolling();

  return (
    <div className={styles.HeroSection} onMouseMove={handleMouseMove} id="home">
      <header
        style={{
          opacity: progressMain > 99 ? 1 : 0,
          top: isScrolling ? "-10rem" : 0,
        }}
      >
        <img src={logoWhite} alt="logo" className={styles.logo} />
        <div className={styles.navigation}>
          <a href="#home">HOME</a>
          <a href="#earnPip">EARN YOUR PIP</a>
          <a href="#locations">TRADING LOCATIONS</a>
          <a href="#wars">TRADE WARS</a>
          <a href="#experts">BUILT BY EXPERTS</a>
        </div>
        <div className={styles.socials}>
          <img
            src={discord}
            alt="logo"
            onClick={() => window.open("https://discord.com/invite/pipworld")}
          />
          <img
            src={x}
            alt="logo"
            onClick={() => window.open("https://x.com/ThePiPWorld")}
          />
          <span onClick={() => setShowRegModal(true)}>REGISTER</span>
        </div>
      </header>
      <img
        className={styles.officeBgLayer1}
        src={pipOfficeBg}
        alt="office bg"
        width="100%"
        height="100%"
        style={
          isMobile
            ? {}
            : {
                marginLeft: handleLayer1Translate().x || "auto",
                marginTop: handleLayer1Translate().y || "auto",
              }
        }
      />
      <img
        className={styles.officeBgLayer2}
        ref={layer2Ref}
        src={heroSectionLayer2Bg}
        alt="office bg"
        width="100%"
        height="100%"
        style={
          isMobile
            ? {}
            : {
                transform: handleLayer2TranslateX(),
              }
        }
      />
      <img
        className={styles.officeBgLayer3}
        ref={layer3Ref}
        src={heroSectionLayer3Bg}
        alt="office bg"
        width="100%"
        height="100%"
        style={
          isMobile
            ? {}
            : {
                transform: handleLayer3TranslateX(),
              }
        }
      />
      <img
        src={overlay}
        alt="overlay"
        className={styles.overlayTop}
        width="100%"
        height="100%"
      />
      <div
        className={styles.heading}
        ref={headingRef}
        style={
          isMobile
            ? {}
            : {
                marginLeft: headingTransforn().x || "auto",
                marginTop: headingTransforn().y || "auto",
              }
        }
      >
        <div style={{ overflow: "hidden" }}>
          <motion.span
            className="clipText"
            ref={text1}
            initial={{ y: 140 }}
            animate={inView ? { y: 0 } : {}}
            transition={{
              duration: 1,
              ease: [0.25, 0.1, 0.25, 1],
              delay: 0.2,
            }}
          >
            PIP TRADER
          </motion.span>
        </div>
        <div className={styles.subheading}>
          Build Your Trading Empire, and Earn Real Money.
        </div>
      </div>
      <button className={styles.cta} onClick={() => setShowRegModal(true)}>
        REGISTER FOR BETA
      </button>
      <img
        src={arrowDown}
        alt="arrow down"
        width="35px"
        className={styles.arrowDownIcon}
      />
    </div>
  );
};

export default HeroSection;
