import "./App.css";
import HeroSection from "./components/pages/HeroSection";
import LoadingScreen from "./components/pages/LoadingScreen";
import { useEffect, useRef, useState } from "react";
import EarnsYouPip from "./components/pages/EarnsYouPip";
import TradingTeam from "./components/pages/TradingTeam";
import TradingLocation from "./components/pages/TradingLocation";
import CustomiseOffice from "./components/pages/CustomiseOffice";
import TradeWars from "./components/pages/TradeWars";
import AIPoweredGaming from "./components/pages/AIPoweredGaming";
import Experts from "./components/pages/Experts";
import Lenis from "@studio-freight/lenis";
import { useScroll } from "framer-motion";
import { isMobile } from "react-device-detect";
import RegistrationModal from "./components/molecules/RegistrationModal";

function App() {
  const [height, setHeight] = useState(false);
  const [progressMain, setProgressMain] = useState(0);
  const [removeLoadingScreeFromDom, setRemoveLoadingScreeFromDom] =
    useState(false);

  const [showRegModal, setShowRegModal] = useState(false)

  useEffect(() => {
    const height = window.innerHeight;
    setHeight(height);
  }, []);

  useEffect(() => {
    let interval;

    if (progressMain === 69) {
      interval = setTimeout(() => {
        interval = setInterval(() => {
          if (progressMain < 100) {
            setProgressMain((prevProgress) => prevProgress + 1);
          }
        }, 50);
      }, 2500);
    } else {
      interval = setInterval(() => {
        if (progressMain < 100) {
          setProgressMain((prevProgress) => prevProgress + 1);
        }
      }, 60);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(interval);
    };
  }, [progressMain]);

  useEffect(() => {
    if (!isMobile) {
      const lenis = new Lenis({
        lerp: 0.1,
        smooth: true,
        direction: "vertical",
        wheelMultiplier: 0.5
      });

      function raf(time) {
        lenis.raf(time);
        requestAnimationFrame(raf);
      }

      requestAnimationFrame(raf);

      return () => {
        lenis.destroy();
      };
    }
  }, []);

  const disableScroll = { height: `${height}px`, overflow: "hidden" };

  const stickyContainerRefLocation = useRef(null);

  const { scrollYProgress } = useScroll({
    target: stickyContainerRefLocation,
    offset: ["start start", "end end"],
  });

  useEffect(() => {
    if (progressMain === 100) {
      const timer = setTimeout(() => {
        setRemoveLoadingScreeFromDom(true);
      }, 2500);
      return () => clearTimeout(timer);
    }
  }, [progressMain]);

  return (
    <div
      className="App"
      style={!removeLoadingScreeFromDom ? disableScroll : null}
    >
      {showRegModal ? <RegistrationModal setShowRegModal={setShowRegModal}/> : null}
      {!removeLoadingScreeFromDom ? (
        <LoadingScreen height={height} progressMain={progressMain} />
      ) : null}
      <HeroSection progressMain={progressMain} setShowRegModal={setShowRegModal} />
      <EarnsYouPip />
      <TradingTeam />
      <div className="stickyContainer" ref={stickyContainerRefLocation}>
        <TradingLocation scrollYProgress={scrollYProgress} />
        <CustomiseOffice scrollYProgress={scrollYProgress} />
      </div>
      <TradeWars />
      <AIPoweredGaming />
      <Experts />
    </div>
  );
}

export default App;
