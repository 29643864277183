// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_background__qDXvf{
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    transition: all ease-in-out 2s;
}

.styles_bigBuilding__HzC3S{
    position: absolute;
    right: 1%;
    transition: all ease-in-out 1s;
}

.styles_mediumBuilding__HZsn\\+{
    position: absolute;
    left: 40%;
    transition: all ease-in-out 1s;
    transition-delay: 0.2s;
}

.styles_smallBuilding__uPhxk{
    position: absolute;
    left: 5%;
    transition: all ease-in-out 1s;
    transition-delay: 0.4s;
}

.styles_overlay__G9Jir{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    opacity: 1;
}

@media screen and (max-width: 768px) {
    .styles_background__qDXvf{
        object-fit: cover;
    }
    .styles_overlay__G9Jir{
        height: 100svh;
        object-fit: cover;
    }
    .styles_bigBuilding__HzC3S{
        position: absolute;
        right: 0;
        transition: all ease-in-out 1s;
    }
    .styles_mediumBuilding__HZsn\\+ {
        left: 30%;
    }
    .styles_smallBuilding__uPhxk{
        left: 5%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/molecules/MapWithBuildingsBakground/styles.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,OAAO;IACP,QAAQ;IACR,SAAS;IACT,MAAM;IACN,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,8BAA8B;IAC9B,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,8BAA8B;IAC9B,sBAAsB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,QAAQ;IACR,YAAY;IACZ,UAAU;AACd;;AAEA;IACI;QACI,iBAAiB;IACrB;IACA;QACI,cAAc;QACd,iBAAiB;IACrB;IACA;QACI,kBAAkB;QAClB,QAAQ;QACR,8BAA8B;IAClC;IACA;QACI,SAAS;IACb;IACA;QACI,QAAQ;IACZ;AACJ","sourcesContent":[".background{\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    top: 0;\n    transition: all ease-in-out 2s;\n}\n\n.bigBuilding{\n    position: absolute;\n    right: 1%;\n    transition: all ease-in-out 1s;\n}\n\n.mediumBuilding{\n    position: absolute;\n    left: 40%;\n    transition: all ease-in-out 1s;\n    transition-delay: 0.2s;\n}\n\n.smallBuilding{\n    position: absolute;\n    left: 5%;\n    transition: all ease-in-out 1s;\n    transition-delay: 0.4s;\n}\n\n.overlay{\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    width: 100vw;\n    opacity: 1;\n}\n\n@media screen and (max-width: 768px) {\n    .background{\n        object-fit: cover;\n    }\n    .overlay{\n        height: 100svh;\n        object-fit: cover;\n    }\n    .bigBuilding{\n        position: absolute;\n        right: 0;\n        transition: all ease-in-out 1s;\n    }\n    .mediumBuilding {\n        left: 30%;\n    }\n    .smallBuilding{\n        left: 5%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"background": `styles_background__qDXvf`,
	"bigBuilding": `styles_bigBuilding__HzC3S`,
	"mediumBuilding": `styles_mediumBuilding__HZsn+`,
	"smallBuilding": `styles_smallBuilding__uPhxk`,
	"overlay": `styles_overlay__G9Jir`
};
export default ___CSS_LOADER_EXPORT___;
