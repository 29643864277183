import React, { useEffect, useRef, useState } from "react";
import styles from "./styles.module.css";
import { motion, useInView, useTransform } from "framer-motion";

import building3 from "../../../assets/buildings/building3.png";
import building4 from "../../../assets/buildings/building4.png";
import building5 from "../../../assets/buildings/building5.png";
import building6 from "../../../assets/buildings/building6.png";
import building7 from "../../../assets/buildings/building7.png";
import building8 from "../../../assets/buildings/building8.png";
import newYorkFlag from "../../../assets/newyork.png";
import parisFlag from "../../../assets/paris.png";
import abudhabiFlag from "../../../assets/abudhabi.png";

import { isMobile } from "react-device-detect";

const TradingLocation = ({ scrollYProgress }) => {
  const text1 = useRef(null);
  const text2 = useRef(null);
  const text3 = useRef(null);

  const tradingLocationRef = useRef(null);
  const inView = useInView(tradingLocationRef);

  const [cityIndex, setCityIndex] = useState(0);
  const [localIndex, setLocalIndex] = useState(cityIndex);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLocalIndex(cityIndex);
    }, 500);
    return () => clearTimeout(timeout);
  }, [cityIndex]);

  const variants = {
    enter: { opacity: 0, x: 200 },
    center: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -100 },
  };

  const cities = [
    {
      name: "New York",
      bigBuilding: building3,
      smallBuilding: building4,
      flag: newYorkFlag,
    },
    {
      name: "Paris",
      bigBuilding: building5,
      smallBuilding: building6,
      flag: parisFlag,
    },
    {
      name: "Abu Dhabi",
      bigBuilding: building8,
      smallBuilding: building7,
      flag: abudhabiFlag,
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCityIndex((prevIndex) => (prevIndex + 1) % cities.length);
    }, 2000);
    return () => clearInterval(interval);
  }, [cities.length]);

  const scale = useTransform(scrollYProgress, [0, 1], [1, 0.8]);
  const rotate = useTransform(scrollYProgress, [0, 1], [0, isMobile ? -2 : -5]);
  const opacity = useTransform(
    scrollYProgress,
    [0.5, 1],
    [1, isMobile ? 0 : 1]
  );

  return (
    <motion.div
      className={styles.tradingLocation}
      ref={tradingLocationRef}
      style={{ scale, rotate, opacity }}
      id="locations"
    >
      <div className={styles.tradingLocationWindow}>
        <div className={styles.textSection}>
          <div className="heading" style={{ overflow: "hidden" }}>
            <motion.span
              className="clipText"
              ref={text1}
              initial={{ y: 140 }}
              animate={inView ? { y: 0 } : {}}
              transition={{
                duration: 1,
                ease: [0.25, 0.1, 0.25, 1],
                delay: 0.2,
              }}
            >
              CHOOSE YOUR
            </motion.span>
          </div>
          <div className="heading" style={{ overflow: "hidden" }}>
            <motion.span
              className="clipText"
              ref={text2}
              initial={{ y: 140 }}
              animate={inView ? { y: 0 } : {}}
              transition={{
                duration: 1,
                ease: [0.25, 0.1, 0.25, 1],
                delay: 0.4,
              }}
            >
              TRADING
            </motion.span>
          </div>
          <div className="heading" style={{ overflow: "hidden" }}>
            <motion.span
              className="clipText"
              ref={text3}
              initial={{ y: 140 }}
              animate={inView ? { y: 0 } : {}}
              transition={{
                duration: 1,
                ease: [0.25, 0.1, 0.25, 1],
                delay: 0.6,
              }}
            >
              LOCATION
            </motion.span>
          </div>
          <div className="subHeadingWrapper">
            <div className="subHeading">Explore global trading hubs.</div>
            <div className="subHeading">
              Choose the best spots for your strategy.
            </div>
            <div className="subHeading">Trade in offices located in:</div>
          </div>
          <div className={styles.flag}>
            <img src={cities[localIndex].flag} alt="Flag" />
            <span>{cities[localIndex].name}</span>
          </div>
          <motion.div
            className={styles.smallBuilding}
            key={`small-${localIndex}`}
            initial="enter"
            animate="center"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.5 }}
          >
            <img src={cities[localIndex].smallBuilding} alt="Small Building" />
          </motion.div>
          <motion.div
            className={styles.bigBuilding}
            key={`big-${localIndex}`}
            initial="enter"
            animate="center"
            exit="exit"
            variants={variants}
            transition={{ duration: 0.5 }}
          >
            <img src={cities[localIndex].bigBuilding} alt="Big Building" />
          </motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default TradingLocation;
