import React, { useCallback, useEffect, useRef, useState } from "react";
import { motion, useAnimation, useInView } from "framer-motion";
import "./styles.css";
import "../../../App.css";

import Logo1 from "../../../assets/trader_groups/Logo-1.svg";
import Logo2 from "../../../assets/trader_groups/Logo-2.svg";
import Logo3 from "../../../assets/trader_groups/Logo-3.svg";
import Logo4 from "../../../assets/trader_groups/Logo-4.svg";
import Logo5 from "../../../assets/trader_groups/Logo-5.svg";
import Logo6 from "../../../assets/trader_groups/Logo-6.svg";
import Logo7 from "../../../assets/trader_groups/Logo-7.svg";
import Logo8 from "../../../assets/trader_groups/Logo-8.svg";
import Logo9 from "../../../assets/trader_groups/Logo-9.svg";
import Logo10 from "../../../assets/trader_groups/Logo-10.svg";

import char0 from "../../../assets/char-0.png";
import char1 from "../../../assets/char-1.png";
import char2 from "../../../assets/char-2.png";

const TradingTeam = () => {
  const tradingTeamRef = useRef(null);
  const inView = useInView(tradingTeamRef);
  const [count, setCount] = useState(1);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    let intervalId;
    if (!isHovered) {
      intervalId = setInterval(() => {
        setCount((prevCount) => (prevCount === 2 ? 0 : prevCount + 1));
      }, 200);
    }
    return () => clearInterval(intervalId);
  }, [isHovered]);

  const controls = useAnimation();
  const imageControls = useAnimation();
  const sliderControls = useAnimation();

  const divRef = useRef(null);
  const imgRef = useRef(null);
  const text1 = useRef(null);
  const text2 = useRef(null);

  const handleScroll = useCallback(() => {
    const scrollY = window.scrollY;
    const rotationAngle = scrollY * 0.1;

    controls.set({
      rotate: rotationAngle,
      background: `hsl(${scrollY / 5}, 50%, 50%)`,
    });
    imageControls.set({
      rotate: -rotationAngle,
    });
    sliderControls.set({
      background: `hsl(${scrollY / 4.8}, 50%, 50%)`,
    });
  }, [controls, imageControls, sliderControls]);

  useEffect(() => {
    const scrollHandler = () => {
      requestAnimationFrame(handleScroll);
    };

    window.addEventListener("scroll", scrollHandler, { passive: true });
    return () => {
      window.removeEventListener("scroll", scrollHandler);
    };
  }, [handleScroll]);

  const traderGroups = [
    Logo1,
    Logo2,
    Logo3,
    Logo4,
    Logo5,
    Logo6,
    Logo7,
    Logo8,
    Logo9,
    Logo10,
  ];

  const spinWheelChars = [
    { name: "char1", img: char0 },
    { name: "char2", img: char1 },
    { name: "char3", img: char2 },
  ];

  return (
    <div className="trading-team" id="tradingTeam">
      <img
        className="background-img"
        src={spinWheelChars[count].img}
        alt={spinWheelChars[count].name}
        ref={tradingTeamRef}
      />
      <span className="hide-legs" />
      <div
        className="trader-swap"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <motion.div ref={divRef} className="img-wrapper" animate={controls}>
          <motion.div ref={imgRef} animate={imageControls}>
            <img
              className="forground-img"
              src={spinWheelChars[count].img}
              alt={spinWheelChars[count].name}
            />
          </motion.div>
        </motion.div>
        <span />
        <div className="logos">
          {Array(4)
            .fill(null)
            .map(() => (
              <motion.div className="logo_items" animate={sliderControls}>
                {traderGroups.map((group, index) => (
                  <img className="logo" key={index} src={group} alt={index} />
                ))}
              </motion.div>
            ))}
        </div>
      </div>

      <div className="text-section">
        <div className="heading" style={{ overflow: "hidden" }}>
          <motion.span
            className="clipText"
            ref={text1}
            initial={{ y: 140 }}
            animate={inView ? { y: 0 } : {}}
            transition={{
              duration: 1,
              ease: [0.25, 0.1, 0.25, 1],
              delay: 0.2,
            }}
          >
            BUILD YOUR
          </motion.span>
        </div>
        <div className="heading" style={{ overflow: "hidden" }}>
          <motion.span
            className="clipText"
            ref={text2}
            initial={{ y: 140 }}
            animate={inView ? { y: 0 } : {}}
            transition={{
              duration: 1,
              ease: [0.25, 0.1, 0.25, 1],
              delay: 0.4,
            }}
          >
            TRADING TEAM
          </motion.span>
        </div>
        <div className="subHeadingWrapper">
          <div className="subHeading">Pick characters with unique skills.</div>
          <div className="subHeading">
            Create a team that matches your strategy.
          </div>
        </div>
      </div>
    </div>
  );
};

export default TradingTeam;
