import React, { useState, useEffect } from "react";
import styles from "./styles.module.css";

const TypeWritterText = ({ text, delay = 1000 }) => {
  const [typedText, setTypedText] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTyping(true);
    }, delay);

    return () => clearTimeout(timeout);
  }, [delay]);

  useEffect(() => {
    if (isTyping) {
      const interval = setInterval(() => {
        if (typedText.length === text.length) {
          setIsTyping(false);
        } else {
          setTypedText(text.substring(0, typedText.length + 1));
        }
      }, 20);

      return () => clearInterval(interval);
    }
  }, [isTyping, typedText, text]);

  const carrot = isTyping ? <span className={styles.carrot}>|</span> : null;
  return (
    <div className={styles.typeWritterText}>
      {typedText}
      {carrot}
    </div>
  );
};

export default TypeWritterText;
