export function validateFormData(formData) {
    const { country, email, wallet } = formData;
    const errors = {};
  
    if (!email || !isValidEmail(email)) {
      errors.Email = 'Invalid/Missing email address';
    }
  
    if (!country) {
      errors.Country = 'Country is required';
    }
  
    if (wallet && !isValidEthereumAddress(wallet)) {
      errors.wallet = 'Invalid Ethereum wallet';
    }
  
    return { 
      errors,
      isValid: Object.keys(errors).length === 0
    };
  }

  function isValidEmail(email) {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }
  
  function isValidEthereumAddress(address) {
    const regex = /^(0x)?[0-9a-fA-F]{40}$/;
    return regex.test(address.trim());
  }
  